import React from 'react';
import { navigate } from 'gatsby';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import AXIOS from '../../../axios-config';
import AdminLayout from '../../components/admin/adminlayout';
import Spinner from '../../components/spinner';
import cogoToast from 'cogo-toast';
import { setAuthUser, setToken } from '../../services/auth';

class Login extends React.Component {
  state = {
    isLoading: false
  };

  initialValues = {
    username: '',
    password: ''
  };

  validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Please enter your username')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255'),
    password: Yup.string()
      .required('Please enter your password')
      .min(1, 'Must have a character')
      .max(255, 'Must be shorter than 255')
  });

  handleSubmit = (values, { setSubmitting, resetForm }) => {
    this.setState({
      isLoading: true
    });
    setSubmitting(true);

    AXIOS.post('/api/login', values)
      .then(({ data }) => {
        this.setState({
          isLoading: false
        });
        setToken(data.token);
        setAuthUser(data);
        setSubmitting(false);
        resetForm();
        cogoToast.success('Login is Successful', { position: 'top-center' });

        if (data.user.roles.indexOf('Press') > -1) {
          navigate('/admin/press-form');
        } else if (data.user.roles.indexOf('Western Region') > -1) {
          navigate('/admin/westernregion-forms');
        } else if (data.user.roles.indexOf('Experiences') > -1) {
          navigate('/admin/experiences');
        } else if (data.user.roles.indexOf('BMW Group Events West Coast') > -1) {
          navigate('/admin/groupevents/westcoast');
        } else if (data.user.roles.indexOf('BMW Group Events East Coast') > -1) {
          navigate('/admin/groupevents/eastcoast');
        } else if (data.user.roles.indexOf('MINI Group Events West Coast') > -1) {
          navigate('/admin/groupevents/westcoast');
        } else if (data.user.roles.indexOf('MINI Group Events East Coast') > -1) {
          navigate('/admin/groupevents/eastcoast');
        } else if (data.user.roles.indexOf('Moto Twisted Tour') > -1) {
          navigate('/admin/mototour/twistedtour');
        } else if (data.user.roles.indexOf('Moto Adventure Tour') > -1) {
          navigate('/admin/mototour/adventuretour');
        } else if (data.user.roles.indexOf('Moto International Tours') > -1) {
            navigate('/admin/mototour/internationaltours');
        } else if (data.user.roles.indexOf('Moto Private Tour') > -1) {
              navigate('/admin/mototour/privatetour');
        } else if (data.user.roles.indexOf('Mini SC') > -1) {
          navigate('/admin/minisc');
        } else if (data.user.roles.indexOf('Planner Registrations') > -1) {
          navigate('/admin/plannerregistration/plannerregistrationtable2');
        } else {
          navigate('/admin/users');
        }

      })
      .catch((err) => {
        this.setState({
          isLoading: false
        });
        setSubmitting(false);
        cogoToast.error('Username or Password is incorrect', { position: 'top-center' });
      });
  };
  render() {
    return (
      <AdminLayout>
        <div className="form">
          <div className="container login">
            <div className="form-header">
              <h1>Log in</h1>
            </div>
            <Formik
              initialValues={this.initialValues}
              validationSchema={this.validationSchema}
              onSubmit={this.handleSubmit}
            >
              {({ values, errors, touched, handleSubmit, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <div className="input__group">
                    <label htmlFor="username">Username</label>
                    <Field type="text" name="username" />
                  </div>
                  <div className="input__group">
                    <label htmlFor="password">Password</label>
                    <Field type="password" name="password" />
                  </div>
                  <button className="btn btn-blue" type="submit" disabled={isSubmitting}>
                    {this.state.isLoading ? <Spinner /> : 'Submit'}
                  </button>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </AdminLayout>
    );
  }
}
export default Login;
