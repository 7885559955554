import React from "react";
import { Router } from "@reach/router";

import PrivateRoute from "../components/admin/privateroutes";
import Roles from "../components/admin/roles";
import MiniGroupEventDetail from "../components/admin/mini-groupevent-detail";
import GroupEventDetail from "../components/admin/groupevent-detail";
import WesternRegionDetail from "../components/admin/westernregion-detail";
import GroupEventEastCoast from "../components/admin/groupevents/eastcoast";
import GroupEventWestCoast from "../components/admin/groupevents/westcoast";
import WesternRegionForm from "../components/admin/westernregion-forms";
import ExperiencesForm from "../components/admin/experiences-form";
import PressForm from "../components/admin/press-form";
import Login from "../components/admin/login";
import Register from "../components/admin/register";
import Users from "../components/admin/users";
import M4GT4Forms from "../components/admin/m4gt4-forms";
import MiniGroupEventWestCoast from "../components/admin/minigroupevents/westcoast";
import MiniGroupEventEastCoast from "../components/admin/minigroupevents/eastcoast";
import TwistedTour from "../components/admin/mototour/twistedtour";
import AdventureTour from "../components/admin/mototour/adventuretour";
import InternationalTours from "../components/admin/mototour/internationaltours";
import PrivateTour from "../components/admin/mototour/privatetour";
import R18SmokyMountainsTour from "../components/admin/mototour/r18smokymountainstour";
import MotoTourDetail from "../components/admin/mototour-detail";
import OffRoadTraining from "../components/admin/privatetrainings/offroadtraining";
import OnRoadTraining from "../components/admin/privatetrainings/onroadtraining";
import SouthOCCCTable from "../components/admin/southoccc/SouthOCCCTable";
import PlannerRegistrationTable2 from "../components/admin/plannerregistration/plannerregistrationtable2";
import IndianapolisMotorSpeedway from "../components/admin/mschoolontourevents/indianapolismotorspeedway";
import VirginiaInternationalRaceway from "../components/admin/mschoolontourevents/virginiainternationalraceway";
import Other from "../components/admin/mschoolontourevents/other";
import PrivateTrainingDetail from "../components/admin/privatetraining-detail";
import MSchoolOnTourEventDetail from "../components/admin/mschoolontourevents-detail";
import SouthOCCCDetail from "../components/admin/southoccc-detail";
import PlannerRegistrationDetail from "../components/admin/plannerregistration-detail";
import MiniSC from "../components/admin/minisc";
import PressInquiry from "../components/admin/press-inquiry";
import Nurburgring from "../components/admin/nurburgring-form";
import GroupEventIndianapolis from "../components/admin/groupevents/indianapolis";
import PgaWest from "../components/admin/pga-west";
import LaasContest from "../components/admin/laas-contest";

const Admin = () => (
  <Router>
    <PrivateRoute path="/admin/users" component={Users} roles={[Roles.admin]} />
    <PrivateRoute
      path="/admin/groupevents/westcoast"
      component={GroupEventWestCoast}
      roles={[Roles.bmwGroupEventWestCoast]}
    />
    <PrivateRoute
      path="/admin/groupevents/eastcoast"
      component={GroupEventEastCoast}
      roles={[Roles.bmwGroupEventEastCoast]}
    />
    <PrivateRoute
      path="/admin/groupevents/indianapolis"
      component={GroupEventIndianapolis}
      roles={[Roles.bmwGroupEventEastCoast]}
    />
    <PrivateRoute
      path="/admin/minigroupevents/westcoast"
      component={MiniGroupEventWestCoast}
      roles={[Roles.miniGroupEventWestCoast]}
    />
    <PrivateRoute
      path="/admin/minigroupevents/eastcoast"
      component={MiniGroupEventEastCoast}
      roles={[Roles.miniGroupEventEastCoast]}
    />
    <PrivateRoute
      path="/admin/westernregion-forms"
      component={WesternRegionForm}
      roles={[Roles.westernRegion]}
    />
    <PrivateRoute
      path="/admin/experiences-form"
      component={ExperiencesForm}
      roles={[Roles.experiences]}
    />
    <PrivateRoute
      path="/admin/minisc"
      component={MiniSC}
      roles={[Roles.minisc]}
    />
    <PrivateRoute
      path="/admin/press-form"
      component={PressForm}
      roles={[Roles.press]}
    />
    <PrivateRoute
      path="/admin/press-inquiry"
      component={PressInquiry}
      roles={[Roles.press]}
    />
    <PrivateRoute
      path="/admin/pga-west"
      component={PgaWest}
      roles={[Roles.admin]}
    />
    <PrivateRoute
      path="/admin/mototour/twistedtour"
      component={TwistedTour}
      roles={[Roles.motoTwistedTour]}
    />
    <PrivateRoute
      path="/admin/mototour/adventuretour"
      component={AdventureTour}
      roles={[Roles.motoAdventureTour]}
    />
    <PrivateRoute
      path="/admin/mototour/internationaltours"
      component={InternationalTours}
      roles={[Roles.motoInternationalTours]}
    />
    <PrivateRoute
      path="/admin/mototour/privatetour"
      component={PrivateTour}
      roles={[Roles.motoPrivateTour]}
    />
    <PrivateRoute
      path="/admin/mototour/r18smokymountainstour"
      component={R18SmokyMountainsTour}
      roles={[Roles.r18SmokyMountainsTour]}
    />
    <PrivateRoute
      path="/admin/privatetraining/offroad"
      component={OffRoadTraining}
      roles={[Roles.OffRoadTraining]}
    />
    <PrivateRoute
      path="/admin/privatetraining/onroad"
      component={OnRoadTraining}
      roles={[Roles.OnRoadTraining]}
    />
    <PrivateRoute
      path="/admin/mschoolontourevents/indianapolismotorspeedway"
      component={IndianapolisMotorSpeedway}
      roles={[Roles.IndianapolisMotorSpeedway]}
    />
    <PrivateRoute
      path="/admin/mschoolontourevents/virginiainternationalraceway"
      component={VirginiaInternationalRaceway}
      roles={[Roles.VirginiaInternationalRaceway]}
    />
    <PrivateRoute
      path="/admin/mschoolontourevents/other"
      component={Other}
      roles={[Roles.Other]}
    />
    <PrivateRoute
      path="/admin/southoccc/SouthOCCCTable"
      component={SouthOCCCTable}
      roles={[Roles.SouthOCCC]}
    />
    <PrivateRoute
      path="/admin/plannerregistration/plannerregistrationtable2"
      component={PlannerRegistrationTable2}
      roles={[Roles.PlannerRegistrations]}
    />
    <PrivateRoute
      path="/admin/register"
      component={Register}
      roles={[Roles.admin]}
    />
    <PrivateRoute
      path="/admin/m4gt4-forms"
      component={M4GT4Forms}
      roles={[Roles.admin]}
    />
    <PrivateRoute
      path="/admin/minigroupevents/detail/:id"
      component={MiniGroupEventDetail}
      roles={[Roles.miniGroupEventEastCoast, Roles.miniGroupEventWestCoast]}
    />
    <PrivateRoute
      path="/admin/groupevents/detail/:id"
      component={GroupEventDetail}
      roles={[Roles.bmwGroupEventEastCoast, Roles.bmwGroupEventWestCoast]}
    />
    <PrivateRoute
      path="/admin/westernregion-forms/detail/:id"
      component={WesternRegionDetail}
      roles={[Roles.westernRegion]}
    />
    <PrivateRoute
      path="/admin/mototour/detail/:id"
      component={MotoTourDetail}
      roles={[Roles.motoTwistedTour, Roles.motoAdventureTour]}
    />
    <PrivateRoute
      path="/admin/privatetraining/detail/:id"
      component={PrivateTrainingDetail}
      roles={[Roles.OnRoadTraining, Roles.OffRoadTraining]}
    />
    <PrivateRoute
      path="/admin/southoccc/detail/:id"
      component={SouthOCCCDetail}
      roles={[Roles.SouthOCCC]}
    />
    <PrivateRoute
      path="/admin/plannerregistration/detail/:id"
      component={PlannerRegistrationDetail}
      roles={[Roles.PlannerRegistrations]}
    />
    <PrivateRoute
      path="/admin/mschoolontourevents/detail/:id"
      component={MSchoolOnTourEventDetail}
      roles={[
        Roles.VirginiaInternationalRaceway,
        Roles.IndianapolisMotorSpeedway,
        Roles.Other
      ]}
    />
    <PrivateRoute
      path="/admin/nurburgring"
      component={Nurburgring}
      roles={[Roles.experiences]}
    />
    <PrivateRoute
      path="/admin/laas-contest"
      component={LaasContest}
      roles={[Roles.experiences]}
    />
    <Login path="/admin/login" />
    <Register path="/admin/register" />
  </Router>
);
export default Admin;
